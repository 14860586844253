<template>
	<div>
		<VHeading level="2" screen="mobile">Update password</VHeading>
		<div class="tab-section__content__card tab-section__content__card--profile">
			<form @submit.prevent="submit">
				<div class="profile-inner">
					<div>
						<VDefaultField
							label="Current password"
							:inline="true"
							:error="errors.get('currentPassword')"
						>
							<template #field>
								<VInput
									v-model="form.currentPassword"
									type="password"
									:error="errors.get('currentPassword')"
									@input="errors.clear('currentPassword')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="New password"
							:inline="true"
							:error="errors.get('password')"
						>
							<template #field>
								<VInput
									v-model="form.password"
									type="password"
									:error="errors.get('password')"
									@input="errors.clear('password')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Confirm new password"
							:inline="true"
							:error="errors.get('password2')"
						>
							<template #field>
								<VInput
									v-model="form.password2"
									type="password"
									:error="errors.get('password2')"
									@input="errors.clear('password2')"
								/>
							</template>
						</VDefaultField>
					</div>
				</div>
				<div class="save-btn-box">
					<VButton color="primary" classes="save-btn" type="submit">
						Update password
					</VButton>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { UPDATE_PASSWORD } from '@/store/actions.type';
import Errors from '@/helpers/Errors';

export default {
	data() {
		return {
			form: {
				currentPassword: '',
				password: '',
				password2: ''
			},
			errors: new Errors()
		};
	},
	methods: {
		async submit() {
			try {
				await this.$store.dispatch(UPDATE_PASSWORD, this.form);
				this.resetForm();
				this.$toastr.s('Success', 'Successfully saved password');
			} catch (errors) {
				if (errors.response.status === 422) {
					this.errors.record(errors.response.data.errors);
				}
			}
		},
		resetForm() {
			for (const key in this.form) {
				this.form[key] = '';
			}
		}
	}
};
</script>
