<template>
	<div class="tab-section__content__card--profile">
		<VHeading level="2" screen="mobile">
			Communication preferences
		</VHeading>
		<div class="tab-section__content__card tab-section__content__card--profile">
			<div>
				<div
					v-for="(preference, index) in formatedPreferences"
					:key="`preference-${index}`"
					class="profile-inner"
				>
					<VDefaultField>
						<template #field>
							<VCheckbox :id="`terms-${preference.id}`" v-model="value">
								{{ preference.label }}
							</VCheckbox>
						</template>
					</VDefaultField>
				</div>
			</div>
			<div class="save-btn-box">
				<VButton color="primary" classes="save-btn" @click.stop="onSubmit">
					Save changes
				</VButton>
			</div>
		</div>
	</div>
</template>

// TODO @dotmodus here are some ugly modifications because of Laravel API -
needs to be changed
<script>
import { mapState } from 'vuex';
import { FETCH_PREFERENCES, UPDATE_PREFERENCES } from '@/store/actions.type';

export default {
	data() {
		return {
			value: false
		};
	},
	computed: {
		...mapState({
			preferences: state => state.profile.preferences
		}),
		// ToDo remove this
		formatedPreferences() {
			return [
				{
					id: 1,
					label: 'Send me email updates about Takwimu',
					value: this.preferences.send_email_updates
				}
			];
		}
	},
	async mounted() {
		await this.$store.dispatch(FETCH_PREFERENCES);
		this.value = this.preferences.send_email_updates;
	},
	methods: {
		async onSubmit() {
			await this.$store.dispatch(UPDATE_PREFERENCES, {
				send_email_updates: this.value
			}); // ToDo revert changes when response is changed

			this.$toastr.s('Success', 'Successfully saved preferences');
		},
		cleanForm() {
			return this.preferences.map(preference => {
				return {
					id: preference.id,
					value: preference.value
				};
			});
		}
	}
};
</script>

<style scoped>
/deep/ .form__checkbox + .form__label {
	padding-left: 0;
}

/deep/ .form__checkbox:checked + .form__label:after {
	top: 3px;
}
</style>
