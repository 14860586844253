<template>
	<div class="tab-section__content__card tab-section__content__card--profile">
		<form @submit.prevent="submit">
			<div class="profile-inner">
				<div>
					<VDefaultField
						label="First name"
						:inline="true"
						:error="errors.get('firstName')"
					>
						<template #field>
							<VInput
								v-model="form.firstName"
								type="text"
								placeholder="First name"
								:error="errors.get('firstName')"
								@input="errors.clear('firstName')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField
						label="Last name"
						:inline="true"
						:error="errors.get('lastName')"
					>
						<template #field>
							<VInput
								v-model="form.lastName"
								type="text"
								placeholder="Last name"
								:error="errors.get('lastName')"
								@input="errors.clear('lastName')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField
						label="Email"
						:inline="true"
						:error="errors.get('email')"
					>
						<template #field>
							<VInput
								v-model="form.email"
								type="text"
								placeholder="Email"
								:error="errors.get('email')"
								@input="errors.clear('email')"
							/>
						</template>
					</VDefaultField>
				</div>
				<div>
					<div class="avatar-content">
						<div>
							<croppa
								v-if="form.initialPhoto"
								key="profile-image"
								v-model="form.profilePhoto"
								:initial-image="form.initialPhoto"
								:disable-click-to-choose="true"
								:show-remove-button="false"
								accept="image/*"
								ref="croppa"
							></croppa>
						</div>
						<div class="avatar-action">
							<div>
								<img src="@/assets/imgs/icons/icon-edit.svg" alt="" />
								<a
									@click="form.profilePhoto.chooseFile()"
									href="#"
									class="link link--small"
								>
									Change profile pic
								</a>
							</div>
							<div>
								<img src="@/assets/imgs/icons/icon-delete.svg" alt="" />
								<a
									@click="form.profilePhoto.remove()"
									href="#"
									class="link link--small"
								>
									Delete profile pic
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="save-btn-box">
				<VButton color="primary" classes="save-btn" type="submit">
					Save changes
				</VButton>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_PROFILE } from '@/store/actions.type';
import { SET_USER } from '@/store/mutations.type';

import Errors from '@/helpers/Errors';

export default {
	data() {
		return {
			errors: new Errors(),
			form: {}
		};
	},
	computed: {
		...mapGetters(['currentUser'])
	},
	mounted() {
		this.setForm();
	},
	methods: {
		async submit() {
			try {
				const { data } = await this.$store.dispatch(
					UPDATE_PROFILE,
					this.cleanForm()
				);

				this.$store.commit(SET_USER, data.data);
				this.$toastr.s('Success', 'Successfully saved profile');
			} catch (errors) {
				if (errors.response.status === 422) {
					this.errors.record(errors.response.data.errors);
				}
			}
		},
		cleanForm() {
			var url = this.form.profilePhoto.generateDataUrl();

			return {
				firstName: this.form.firstName,
				lastName: this.form.lastName,
				email: this.form.email,
				profilePhoto: url
			};
		},
		setForm() {
			this.form = { ...this.currentUser, ...{ profilePhoto: {} } };
		}
	}
};
</script>

<style>
.croppa-container {
	border-radius: 50%;
	margin-right: 20px;
}

.croppa-container:hover {
	opacity: 1;
}

.croppa--has-target > canvas {
	border-radius: 50% !important;
}
</style>
