<template>
	<Tab :limiter="false">
		<template #title>Personal details</template>
		<template #content>
			<div class="tab-section__content__main tab-section__content__main--ewg">
				<PersonalDetails />
				<UpdatePassword />
				<CommunicationPreferences />
			</div>
		</template>
	</Tab>
</template>

<script>
import CommunicationPreferences from './CommunicationPreferences';
import PersonalDetails from './PersonalDetails';
import UpdatePassword from './UpdatePassword';

export default {
	name: 'ManageYourProfile',
	components: {
		CommunicationPreferences,
		PersonalDetails,
		UpdatePassword
	}
};
</script>
